<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4 mb-0">{{ pageHeader }}</h1>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4">
          <v-form class="mb-5" ref="createQuoteForm" lazy-validation>
            <v-row class="my-0">
              <v-col cols="6">
                <h6 class="text-subtitle-2 mb-2">{{ 'quoteReference'|localize }} </h6>
                <v-text-field
                  background-color="white"
                  dense
                  outlined
                  v-model="offer_reference"
                  :placeholder="'referencePlaceholder' | localize"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="2">
                <h6 class="text-subtitle-2 mb-2">{{ 'currency'|localize }} *</h6>
                <v-select
                  background-color="white"
                  :items="currency_list"
                  v-model="currency"
                  dense
                  outlined
                  :placeholder="'SEK'"
                  :rules="[validationRules.required]"
                />
              </v-col>
              <v-col cols="4">
                <h6 class="text-subtitle-2 mb-2"> {{ 'price'|localize }} *</h6>
                <v-text-field
                  background-color="white"
                  v-model.number="price"
                  :maxlength="10"
                  :counter="10"
                  dense
                  outlined
                  :placeholder="'pleaseSetThePrice' | localize"
                  :rules="[validationRules.requiredNumber, validationRules.integer]"
                />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{'estimatedTransitTime' | localize}}, {{'days' | localize}} *</h6>
                <v-text-field
                  background-color="white"
                  v-model.number="estimated_transit_time"
                  dense
                  outlined
                  :placeholder="'days' | localize"
                  :rules="[validationRules.requiredNumber, validationRules.integer]"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{'deadlineForBooking' | localize}}</h6>
                  <v-menu
                    ref="latestBooking"
                    v-model="latest_booking_menu"
                    :close-on-content-click="false"
                    :return-value.sync="latest_booking_menu"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="white"
                        :placeholder="'selectDate' | localize"
                        prepend-inner-icon="mdi-calendar"
                        v-model="latest_booking_date"
                        readonly
                        dense
                        outlined
                        v-on="on"
                        clearable
                        @click:clear="latest_booking_date = ''"
                      />
                    </template>
                    <v-date-picker
                      v-model="latest_booking_date"
                      no-title
                      scrollable
                      :min="today"
                      @input="$refs.latestBooking.save(latest_booking_date)"
                    />
                  </v-menu>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="mt-7"
                  background-color="white"
                  v-model="latest_booking_time"
                  :items="methods.intervals()"
                  item-value="key"
                  item-text="value"
                  dense
                  outlined
                  :placeholder="'selectTime' | localize"
                  prepend-inner-icon="mdi-clock"
                />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{'latestTimeCollection' | localize}}</h6>
                  <v-menu
                    ref="latestCollection"
                    v-model="latest_collection_menu"
                    :close-on-content-click="false"
                    :return-value.sync="latest_collection_menu"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="white"
                        :placeholder="'selectDate' | localize"
                        prepend-inner-icon="mdi-calendar"
                        v-model="latest_collection_date"
                        readonly
                        dense
                        outlined
                        v-on="on"
                        clearable
                        @click:clear="latest_collection_date = ''"
                      />
                    </template>
                    <v-date-picker
                      v-model="latest_collection_date"
                      no-title
                      scrollable
                      :min="today"
                      @input="$refs.latestCollection.save(latest_collection_date)"
                    />
                  </v-menu>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="mt-7"
                  background-color="white"
                  v-model="latest_collection_time"
                  :items="methods.intervals()"
                  item-value="key"
                  item-text="value"
                  dense
                  outlined
                  :placeholder="'selectTime' | localize"
                  prepend-inner-icon="mdi-clock"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{'quoteValidTo' | localize}} *</h6>
                <v-menu
                  ref="validTo"
                  v-model="valid_to_menu"
                  :close-on-content-click="false"
                  :return-value.sync="valid_to_menu"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      background-color="white"
                      :placeholder="'selectDate' | localize"
                      prepend-inner-icon="mdi-calendar"
                      v-model="valid_to_date"
                      readonly
                      dense
                      outlined
                      v-on="on"
                      :rules="[validationRules.required]"
                      clearable
                      @click:clear="valid_to_date = ''"
                    />
                  </template>
                  <v-date-picker
                    v-model="valid_to_date"
                    no-title
                    scrollable
                    :min="today"
                    @input="$refs.validTo.save(valid_to_date)"
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="mt-7"
                  background-color="white"
                  v-model="valid_to_time"
                  :items="methods.intervals()"
                  item-value="key"
                  item-text="value"
                  dense
                  outlined
                  :placeholder="'selectTime' | localize"
                  prepend-inner-icon="mdi-clock"
                />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{'environmentalImpact' | localize}}, kg CO<sub>2</sub></h6>
                <v-text-field
                  background-color="white"
                  v-model.number="environmental_impact"
                  dense
                  outlined
                  :rules="[validationRules.number]"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{'attachQuoteFile' | localize}}</h6>
                <div class="d-flex mb-2">
                  <div class="d-flex flex-column">
                    <div class="body-2">
                      {{'availableFileFormats' | localize}}:<br/> PDF, JPG, JPEG, DOC, DOCX<br/> {{ 'maxFileSize'|localize }} - 50Mb.<br/>
                      {{ 'uploadNote'|localize }} <a href="https://document.online-convert.com/convert/xlsx-to-pdf" target="_blank">{{ 'onlineConvertService'|localize }}.</a>
                    </div>
                  </div>
                  <v-btn
                    color="primary"
                    class="white--text text-h4 text-center ml-4"
                    @click="handleFileImport"
                    fab
                    small
                    depressed
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  multiple
                  accept="image/jpg, image/jpeg, application/pdf, application/doc, application/docx"
                  @change="onFileChanged($event)"
                >
                <template v-if="files.length > 0">
                  <div class="d-flex align-center mb-2">
                    <div class="text-caption text-uppercase">{{ 'attachedDocuments'|localize }}:</div>
                    <v-btn icon class="ml-2" color="primary" @click="files = []">
                      <v-icon size="23">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <v-chip
                    v-for="(fileData, index) in files"
                    :key="fileData.id"
                    class="mb-2 mr-2"
                    close-icon="mdi-delete"
                    @click:close="removeFile(index)"
                    :close="!!fileData.file"
                    outlined
                    label
                  >
                    <a v-if="fileData.link" :href="fileData.link" target="_blank" class="text-truncate black--text">{{ fileData.name }}</a>
                    <span v-else class="text-truncate black--text">{{ fileData.name }}</span>
                  </v-chip>
                </template>
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-2 mb-2">{{ 'attachQuoteLink'|localize }} </h6>
                <v-text-field
                  background-color="white"
                  dense
                  outlined
                  v-model="external_file"
                  placeholder="https://example/file.jpg"
                  :rules="[validationRules.url]"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="6">
                <h6 class="text-subtitle-2 mb-2">{{'comment' | localize}}</h6>
                <v-textarea
                  rows="5"
                  v-model="estimated_transit_comment"
                  background-color="white"
                  dense
                  outlined
                  counter="1200"
                  :rules="[commentsMaxLengthRule]"
                  :placeholder="'pleaseEnterExtraInformationHere' | localize"
                />
                <div class="text-subtitle-2 d-flex align-center" style="padding-right: 100px">
                  <v-icon class="mr-2" color="primary">mdi-eye</v-icon>
                  <strong class="primary--text">{{ 'quoteComment'|localize }}</strong>
                </div>
              </v-col>
              <v-col cols="4">
                <h6 class="text-subtitle-2 mb-2">{{'possibleBooking' | localize}}</h6>
                <div class="mb-2">
                  <i class="grey--text">{{ userData.booking_email ? userData.booking_email : userData.email }}</i>
                </div>
                <v-radio-group row v-model="confirm_booking_email" class="my-0 mb-4 py-0" hide-details>
                  <v-radio :value="true" color="orange">
                    <template v-slot:label>
                      <span class="black--text">{{ 'yes'|localize }}</span>
                    </template>
                  </v-radio>
                  <v-radio :value="false" color="orange">
                    <template v-slot:label>
                      <span class="black--text">{{ 'no'|localize }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
                <template v-if="!confirm_booking_email">
                  <v-text-field
                    background-color="white"
                    v-model="booking_email"
                    dense
                    outlined
                    :placeholder="'newBookingEmail' | localize"
                    :rules="[validationRules.email]"
                  />
                </template>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="6">
              <v-btn
                v-if="proposal_id"
                rounded
                depressed
                color="error"
                :loading="deleteLoading"
                :disabled="saveLoading || sendLoading || deleteLoading"
                @click="openDelete(proposal_id)"
              >
                {{ 'delete'|localize }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn
                v-if="status !== STATUSES.ACTION_REQUIRED"
                rounded
                outlined
                color="primary"
                :loading="saveLoading"
                :disabled="saveLoading || sendLoading || deleteLoading"
                @click="$refs.saveDialog.open()"
              >
                {{ 'save'|localize }}
              </v-btn>
              <v-btn
                depressed
                rounded
                color="primary"
                class="ml-2"
                :loading="sendLoading"
                :disabled="saveLoading || sendLoading || deleteLoading"
                @click="$refs.sendDialog.open()"
              >
                {{'sendQuote' | localize}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <SimpleDialog
      ref="saveDialog"
      :title="'saveDialogTitle'|localize"
      :text="'saveDialogText'|localize"
      :accept-text="'save'|localize"
      :decline-text="'back'|localize"
      @accept="saveProposal"
    />
    <SimpleDialog
      ref="sendDialog"
      :title="'sendDialogTitle'|localize"
      :text="'sendDialogText'|localize"
      :accept-text="'send'|localize"
      :decline-text="'back'|localize"
      @accept="sendProposal"
    />
    <SimpleDialog
      ref="deleteDialog"
      :title="'deleteQuoteTitle'|localize"
      :text="'deleteQuoteText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @accept="deleteProposal"
    />
  </v-container>
</template>

<script>
  import localize from '@/filters/localize';
  import validationRules from '../helpers/validationRules';
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import {STATUSES} from '@/enums/statuses';

  export default {
    name: 'CreateQuote',
    components: {SimpleDialog},

    data() {
      return {
        STATUSES,
        request_id: this.$router.currentRoute.query.request_id,
        transport_mode: this.$router.currentRoute.query.mode,
        proposal_id: this.$router.currentRoute.query.proposal_id,
        proposal_action: this.$router.currentRoute.query.action,
        validationRules,
        methods,
        status: null,
        offer_reference: '',
        currency_list: ['SEK', 'USD', 'EUR'],
        currency: '',
        price: '',
        estimated_transit_time: '',
        estimated_transit_comment: '',
        latest_booking_menu: false,
        latest_booking_date: '',
        latest_booking_time: '',
        latest_collection_menu: false,
        latest_collection_date: '',
        latest_collection_time: '',
        valid_to_menu: false,
        valid_to_date: '',
        valid_to_time: '',
        environmental_impact: '',
        files: [],
        external_file: '',
        confirm_booking_email: true,
        booking_email: '',
        saveLoading: false,
        sendLoading: false,
        deleteLoading: false,
        getLoading: false,
      };
    },

    async mounted() {
      if(this.proposal_id) {
        try {
          this.getLoading = true;
          let remoteData = await api.getSingleCompanyProposal(this.proposal_id);
          this.setProposalData(remoteData.data);
          this.getLoading = false;
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          this.getLoading = false;
        }
      }
    },

    computed: {
      userData() {
        return this.$store.getters.userData;
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('myRequests'),
            disabled: false,
            to: {name: 'company-requests'}
          },
          {
            text: localize('requestDetails'),
            disabled: false,
            to: {name: 'company-request-details', query: {request_id: this.request_id, proposal_id: this.proposal_id, mode: this.transport_mode}}
          },
          {
            text: this.pageBreadcrumb,
            disabled: true,
          },
        ]
      },
      pageBreadcrumb() {
        let breadcrumb = 'createQuote';
        if (this.proposal_action === 'edit') breadcrumb = 'editQuote';
        return localize(breadcrumb);
      },
      pageHeader() {
        let title = localize('createQuote');
        if (this.proposal_action === 'edit') title = this.offer_reference;
        return title;
      },
      today() {
        return new Date().toISOString().slice(0, 10);
      },
      commentsMaxLengthRule() {
        return v => v.length <= 1200 || localize('max_1200_characters');
      }
    },

    methods: {
      handleFileImport() {
        this.$refs.uploader.click();
      },
      onFileChanged(event) {
        this.files = this.files.filter(fileData => fileData.file);
        if (event.target.files.length > 0) {
          Object.keys(event.target.files).forEach(key => {
            let fileItem = event.target.files[key]
            let newId = Date.now() + key;
            this.files.push({
              id: newId,
              name: fileItem.name,
              file: fileItem,
            });
          })
        }
        event.target.value = '';
      },
      removeFile(index) {
        this.$delete(this.files, index);
      },
      setProposalData(request_data) {
        this.status = request_data.status;
        this.currency = request_data.currency;
        this.price = request_data.price;
        this.estimated_transit_time = request_data.estimated_transit_time;
        if (request_data.offer_reference) this.offer_reference = request_data.offer_reference;
        if (request_data.estimated_transit_comment) this.estimated_transit_comment = request_data.estimated_transit_comment;
        if (request_data.environmental_impact) this.environmental_impact = request_data.environmental_impact;
        if (request_data.latest_booking_date) this.latest_booking_date = request_data.latest_booking_date;
        if (request_data.latest_booking_time) this.latest_booking_time = this.getTimeHM(request_data.latest_booking_time);
        if (request_data.latest_collection_date) this.latest_collection_date = request_data.latest_collection_date;
        if (request_data.latest_collection_time) this.latest_collection_time = this.getTimeHM(request_data.latest_collection_time);
        this.valid_to_date = request_data.valid_to_date;
        if (request_data.valid_to_time) this.valid_to_time = this.getTimeHM(request_data.valid_to_time);
        if (request_data.external_file) this.external_file = request_data.external_file;
        if (request_data['attachments']) {
          this.files = request_data['attachments'].map((fileData, index) => {
            return {
              id: Date.now() + index,
              name: fileData['attachment_name'],
              link: fileData['attachment_link']
            };
          })
        }
        if (request_data['tmp_booking_email']) {
          this.confirm_booking_email = false;
          this.booking_email = request_data['tmp_booking_email'];
        }
      },
      getTimeHM(string) {
        return string.slice(0, 5);
      },
      queryData() {
        let queryData = new FormData;
        if (this.offer_reference) queryData.append('offer_reference', this.offer_reference);
        if (this.proposal_action === 'edit') queryData.append('id', this.proposal_id);
        queryData.append('request_id', this.request_id);
        queryData.append('company_id', this.userData.company.id);
        queryData.append('transport_mode', this.transport_mode);
        queryData.append('price', this.price);
        queryData.append('currency', this.currency);
        queryData.append('estimated_transit_time', this.estimated_transit_time);
        if (this.estimated_transit_comment) queryData.append('estimated_transit_comment', this.estimated_transit_comment);
        if (this.environmental_impact) queryData.append('environmental_impact', this.environmental_impact);
        if (this.latest_booking_date) queryData.append('latest_booking_date', this.latest_booking_date);
        if (this.latest_booking_time) queryData.append('latest_booking_time', this.latest_booking_time);
        if (this.latest_collection_date) queryData.append('latest_collection_date', this.latest_collection_date);
        if (this.latest_collection_time) queryData.append('latest_collection_time', this.latest_collection_time);
        queryData.append('valid_to_date', this.valid_to_date);
        if (this.valid_to_time) queryData.append('valid_to_time', this.valid_to_time);
        if (this.external_file) queryData.append('external_file', this.external_file);
        if (this.files.length > 0) {
          this.files.forEach(fileData => {
            if (fileData.file) queryData.append('files[]', fileData.file);
          });
        } else {
          queryData.append('files[]',  null);
        }
        if (!this.confirm_booking_email && this.booking_email) {
          queryData.append('tmp_booking_email', this.booking_email);
        }
        return queryData;
      },
      async saveProposal() {
        if (this.$refs.createQuoteForm.validate()) {
          try {
            this.saveLoading = true;
            if (this.proposal_action === 'edit') {
              await api.updateCompanyProposal(this.queryData());
            } else {
              await api.saveCompanyProposal(this.queryData());
            }
            methods.showGlobalSnackbar('success', localize('done'));
            this.$router.push({name: 'company-request-details', query: {request_id: this.request_id, mode: this.transport_mode}});
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      async sendProposal() {
        if (this.$refs.createQuoteForm.validate()) {
          try {
            this.sendLoading = true;
            await api.sendCompanyProposal(this.queryData());
            methods.showGlobalSnackbar('success', localize('done'));
            this.$router.push({name: 'company-request-details', query: {request_id: this.request_id, mode: this.transport_mode}});
          } catch (e) {
            this.sendLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      openDelete() {
        this.$refs.deleteDialog.open();
      },
      async deleteProposal() {
        try {
          this.deleteLoading = true;
          await api.deleteCompanyProposal(this.proposal_id);
          this.$router.push({name: 'company-request-details', query: {request_id: this.request_id, mode: this.transport_mode}});
          methods.showGlobalSnackbar('success', localize('done'));
        } catch (e) {
          this.deleteLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
    },
  };
</script>
